export default [
  {
    path: '/content/contact-information',
    name: 'app-contact-information-index',
    component: () => import('@/views/apps/contact/Index.vue'),
    meta: {
      title: 'Contact Information',
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Contact Information',
          active: true,
        },
      ],
      resource: 'ContactInformation',
      action: 'read',
    },
  },
  {
    path: '/content/contact-information/edit',
    name: 'app-contact-information-edit',
    component: () => import('@/views/apps/contact/Edit.vue'),
    meta: {
      title: 'Contact Information Update',
      pageTitle: 'Content',
      navActiveLink: 'app-contact-information-index',
      breadcrumb: [
        {
          text: 'Contact Information',
          active: true,
        },
      ],
      resource: 'ContactInformation',
      action: 'update',
    },
  },
  {
    path: '/content/highlights',
    name: 'app-highlights-index',
    component: () => import('@/views/apps/highlights/List.vue'),
    meta: {
      title: 'Highlights List',
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Highlights',
          active: true,
        },
      ],
      resource: 'Highlights',
      action: 'read',
    },
  },
  {
    path: '/content/highlights/create',
    name: 'app-highlights-create',
    component: () => import('@/views/apps/highlights/Create.vue'),
    meta: {
      title: 'Create Highlights',
      pageTitle: 'Content',
      navActiveLink: 'app-highlights-index',
      breadcrumb: [
        {
          text: 'Create new Highlights',
          active: true,
        },
      ],
      resource: 'Highlights',
      action: 'create',
    },
  },
  {
    path: '/content/highlights/:id/edit',
    name: 'app-highlights-edit',
    component: () => import('@/views/apps/highlights/Edit.vue'),
    meta: {
      title: 'Edit Highlights',
      navActiveLink: 'app-highlights-index',
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Editing Highlights',
          active: true,
        },
      ],
      resource: 'Highlights',
      action: 'update',
    },
  },
  {
    path: '/content/articles/:type',
    name: 'app-articles-index',
    component: () => import('@/views/apps/faq/articles/ArticleList.vue'),
    meta: {
      title: 'Article List',
      pageTitle: 'Content',
      navActiveLink: 'app-articles-index',
      breadcrumb: [
        {
          text: 'Content',
          to: '/content/contact-information',
        },
        {
          text: 'Articles',
          active: true,
        },
      ],
      resource: 'Article',
      action: 'read',
    },
  },
  {
    path: '/content/articles/new/create',
    name: 'app-articles-create',
    component: () => import('@/views/apps/faq/articles/ArticleCreate.vue'),
    meta: {
      title: 'Article Create',
      pageTitle: 'Content',
      navActiveLink: 'app-articles-index',
      breadcrumb: [
        {
          text: 'Content',
          to: '/content/contact-information',
        },
        {
          text: 'Articles',
          to: '/content/articles/all-article',
        },
        {
          text: 'Create new Article',
          active: true,
        },
      ],
      resource: 'Article',
      action: 'create',
    },
  },
  {
    path: '/content/articles/:id/edit',
    name: 'app-articles-edit',
    component: () => import('@/views/apps/faq/articles/ArticleEdit.vue'),
    meta: {
      title: 'Edit Article',
      pageTitle: 'Content',
      navActiveLink: 'app-articles-index',
      breadcrumb: [
        {
          text: 'Content',
          to: '/content/contact-information',
        },
        {
          text: 'Articles',
          to: '/content/articles/all-article',
        },
        {
          text: 'Edit Article',
          active: true,
        },
      ],
      resource: 'Article',
      action: 'update',
    },
  },
  {
    path: '/content/articles/:id/show',
    name: 'app-articles-show',
    component: () => import('@/views/apps/faq/articles/ArticleDetail.vue'),
    meta: {
      title: 'Article Detail',
      pageTitle: 'Content',
      navActiveLink: 'app-articles-index',
      breadcrumb: [
        {
          text: 'Content',
          to: '/content/contact-information',
        },
        {
          text: 'Articles',
          to: '/content/articles/all-article',
        },
        {
          text: 'Article Detail',
          active: true,
        },
      ],
      resource: 'Article',
      action: 'show',
    },
  },
  {
    path: '/content/article-categories/:type',
    name: 'app-article-categories-index',
    component: () => import('@/views/apps/faq/categories/CategoryList.vue'),
    meta: {
      title: 'Article Category List',
      pageTitle: 'Content',
      navActiveLink: 'app-article-categories-index',
      breadcrumb: [
        {
          text: 'Content',
          to: '/content/contact-information',
        },
        {
          text: 'Article Category',
          active: true,
        },
      ],
      resource: 'ArticleCategory',
      action: 'read',
    },
  },
  {
    path: '/content/article-categories/new/create',
    name: 'app-article-categories-create',
    component: () => import('@/views/apps/faq/categories/CategoryCreate.vue'),
    meta: {
      title: 'Article Category Create',
      pageTitle: 'Content',
      navActiveLink: 'app-article-categories-index',
      breadcrumb: [
        {
          text: 'Content',
          to: '/content/contact-information',
        },
        {
          text: 'Article Category',
          to: '/content/article-categories/all-article-category',
        },
        {
          text: 'Create new Article Category',
          active: true,
        },
      ],
      resource: 'ArticleCategory',
      action: 'create',
    },
  },
  {
    path: '/content/article-categories/:id/edit',
    name: 'app-article-categories-edit',
    component: () => import('@/views/apps/faq/categories/CategoryEdit.vue'),
    meta: {
      title: 'Edit Article Category',
      pageTitle: 'Content',
      navActiveLink: 'app-article-categories-index',
      breadcrumb: [
        {
          text: 'Content',
          to: '/content/contact-information',
        },
        {
          text: 'Article Category',
          to: '/content/article-categories/all-article-category',
        },
        {
          text: 'Edit Article Category',
          active: true,
        },
      ],
      resource: 'ArticleCategory',
      action: 'update',
    },
  },
  {
    path: '/content/article-categories/:id/show',
    name: 'app-article-categories-show',
    component: () => import('@/views/apps/faq/categories/CategoryDetail.vue'),
    meta: {
      title: 'Article Category Detail',
      pageTitle: 'Content',
      navActiveLink: 'app-article-categories-index',
      breadcrumb: [
        {
          text: 'Content',
          to: '/content/contact-information',
        },
        {
          text: 'Article Category',
          to: '/content/article-categories/all-article-category',
        },
        {
          text: 'Article Category Detail',
          active: true,
        },
      ],
      resource: 'ArticleCategory',
      action: 'show',
    },
  },
  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },
]
