export default [
  {
    path: '/agencies/agents',
    name: 'agency-agents-index',
    component: () => import('@/views/agency/agents/AgentList.vue'),
    meta: {
      title: 'Agents',
      pageTitle: 'Agencies',
      breadcrumb: [
        {
          text: 'Agents',
          active: true,
        },
      ],
      resource: 'Agent',
      action: 'read',
    },
  },
  {
    path: '/agencies/agents/:id/show',
    name: 'agency-agents-show',
    component: () => import('@/views/agency/agents/AgentDetail.vue'),
    meta: {
      title: 'Agent Details',
      pageTitle: 'Agencies',
      navActiveLink: 'agency-agents-index',
      breadcrumb: [
        {
          text: 'Agents',
          to: '/agencies/agents',
        },
      ],
      resource: 'Agent',
      action: 'read',
    },
  },
  {
    path: '/agencies/agents/:id/edit',
    name: 'agency-agents-edit',
    component: () => import('@/views/agency/agents/AgentEdit.vue'),
    meta: {
      title: 'Agent Edit',
      pageTitle: 'Agencies',
      navActiveLink: 'agency-agents-index',
      breadcrumb: [
        {
          text: 'Agents',
          to: '/agencies/agents',
        },
      ],
      resource: 'Agent',
      action: 'update',
    },
  },
  {
    path: '/agencies/requests/agents',
    name: 'agencies-agent-profile-update-index',
    component: () => import('@/views/agency/requests/List.vue'),
    meta: {
      title: 'Agent Requests List',
      pageTitle: 'Agencies',
      breadcrumb: [
        {
          text: 'Agent Requests',
          active: true,
        },
      ],
      resource: 'AgentRequest',
      action: 'read',
    },
  },
  {
    path: '/agencies/requests/agents/:id/view',
    name: 'agencies-agent-profile-update-view',
    component: () => import('@/views/agency/requests/Detail.vue'),
    meta: {
      title: 'Agent Requests Detail',
      pageTitle: 'Agencies',
      navActiveLink: 'agencies-agent-profile-update-index',
      breadcrumb: [
        {
          text: 'Agent Requests',
          active: true,
        },
      ],
      resource: 'AgentRequest',
      action: 'show',
    },
  },
  {
    path: '/agencies/articles/:type',
    name: 'agencies-articles-index',
    component: () => import('@/views/agency/articles/ArticleList.vue'),
    meta: {
      title: 'Article List',
      pageTitle: 'Agencies',
      navActiveLink: 'agencies-articles-index',
      breadcrumb: [
        {
          text: 'Articles',
          active: true,
        },
      ],
      resource: 'AgentArticle',
      action: 'read',
    },
  },
  {
    path: '/agencies/articles/new/create',
    name: 'agencies-articles-create',
    component: () => import('@/views/agency/articles/ArticleCreate.vue'),
    meta: {
      title: 'Article Create',
      pageTitle: 'Agencies',
      navActiveLink: 'agencies-articles-index',
      breadcrumb: [
        {
          text: 'Articles',
          to: '/agencies/articles/all-article',
        },
        {
          text: 'Create new Article',
          active: true,
        },
      ],
      resource: 'AgentArticle',
      action: 'create',
    },
  },
  {
    path: '/agencies/articles/:id/edit',
    name: 'agencies-articles-edit',
    component: () => import('@/views/agency/articles/ArticleEdit.vue'),
    meta: {
      title: 'Edit Article',
      pageTitle: 'Agencies',
      navActiveLink: 'agencies-articles-index',
      breadcrumb: [
        {
          text: 'Articles',
          to: '/agencies/articles/all-article',
        },
        {
          text: 'Edit Article',
          active: true,
        },
      ],
      resource: 'AgentArticle',
      action: 'update',
    },
  },
  {
    path: '/agencies/articles/:id/show',
    name: 'agencies-articles-show',
    component: () => import('@/views/agency/articles/ArticleDetail.vue'),
    meta: {
      title: 'Article Detail',
      pageTitle: 'Agencies',
      navActiveLink: 'agencies-articles-index',
      breadcrumb: [
        {
          text: 'Articles',
          to: '/agencies/articles/all-article',
        },
        {
          text: 'Article Detail',
          active: true,
        },
      ],
      resource: 'AgentArticle',
      action: 'show',
    },
  },
  {
    path: '/agencies/article-categories/:type',
    name: 'agencies-article-categories-index',
    component: () => import('@/views/agency/categories/CategoryList.vue'),
    meta: {
      title: 'Article Category List',
      pageTitle: 'Agencies',
      navActiveLink: 'agencies-article-categories-index',
      breadcrumb: [
        {
          text: 'Article Category',
          active: true,
        },
      ],
      resource: 'AgentArticleCategory',
      action: 'read',
    },
  },
  {
    path: '/agencies/article-categories/new/create',
    name: 'agencies-article-categories-create',
    component: () => import('@/views/agency/categories/CategoryCreate.vue'),
    meta: {
      title: 'Article Category Create',
      pageTitle: 'Agencies',
      navActiveLink: 'agencies-article-categories-index',
      breadcrumb: [
        {
          text: 'Article Category',
          to: '/agencies/article-categories/all-article-category',
        },
        {
          text: 'Create new Article Category',
          active: true,
        },
      ],
      resource: 'AgentArticleCategory',
      action: 'create',
    },
  },
  {
    path: '/agencies/article-categories/:id/edit',
    name: 'agencies-article-categories-edit',
    component: () => import('@/views/agency/categories/CategoryEdit.vue'),
    meta: {
      title: 'Edit Article Category',
      pageTitle: 'Agencies',
      navActiveLink: 'agencies-article-categories-index',
      breadcrumb: [
        {
          text: 'Article Category',
          to: '/agencies/article-categories/all-article-category',
        },
        {
          text: 'Edit Article Category',
          active: true,
        },
      ],
      resource: 'AgentArticleCategory',
      action: 'update',
    },
  },
  {
    path: '/agencies/article-categories/:id/show',
    name: 'agencies-article-categories-show',
    component: () => import('@/views/agency/categories/CategoryDetail.vue'),
    meta: {
      title: 'Article Category Detail',
      pageTitle: 'Agencies',
      navActiveLink: 'agencies-article-categories-index',
      breadcrumb: [
        {
          text: 'Article Category',
          to: '/agencies/article-categories/all-article-category',
        },
        {
          text: 'Article Category Detail',
          active: true,
        },
      ],
      resource: 'AgentArticleCategory',
      action: 'show',
    },
  },
]
